@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  width: 100%;
  overflow-x: hidden;
}

video {
  height: '100%';
  object-fit: cover;
}

@layer utilities {
  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    @apply -translate-y-2 text-xsmall-regular;
  }

  input:focus ~ label {
    @apply left-0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.font-itc {
  font-family: 'ITC';
}

.font-dm {
  font-family: 'DM Sans';
}

@layer components {
  .content-container {
    @apply max-w-[1440px] w-full mx-auto px-8;
  }

  .contrast-btn {
    @apply px-4 py-2 transition-colors duration-200 ease-in border border-black rounded-full hover:bg-black hover:text-white;
  }

  .text-xsmall-regular {
    @apply text-[10px] leading-4 font-normal;
  }

  .text-small-regular {
    @apply text-xs font-normal leading-5;
  }

  .text-small-semi {
    @apply text-xs font-semibold leading-5;
  }

  .text-base-regular {
    @apply text-sm font-normal leading-6;
  }

  .text-base-semi {
    @apply text-sm font-semibold leading-6;
  }

  .text-large-regular {
    @apply text-base font-normal leading-6;
  }

  .text-large-semi {
    @apply text-base font-semibold leading-6;
  }

  .text-xl-regular {
    @apply text-2xl leading-[36px] font-normal;
  }

  .text-xl-semi {
    @apply text-2xl leading-[36px] font-semibold;
  }

  .text-2xl-regular {
    @apply text-[30px] leading-[48px] font-normal;
  }

  .text-2xl-semi {
    @apply text-[30px] leading-[48px] font-semibold;
  }

  .text-3xl-regular {
    @apply text-[36px] leading-[48px] font-normal;
  }

  .text-3xl-semi {
    @apply text-[36px] leading-[48px] font-semibold;
  }
}

.react-tel-input .country-list .country {
  padding: 5px 50px !important;
}

em {
  font-family: 'DM Sans Regular, sans-serif' !important;
}

.grecaptcha-badge {
  display: none !important;
}

em {
  font-family: 'DM Sans, sans-serif' !important;
}

.form-control {
  width: 100% !important;
}

.responsiveTable td .tdBefore {
  display: none;
}

@media screen and (max-width: 62em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none; for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .responsiveTable tbody tr {
    padding: 0.25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */

    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border-bottom: 1px solid lightgrey !important;
  }

  .responsiveTable td .tdBefore {
    position: absolute;
    display: block;

    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
}

.pdpa::-webkit-scrollbar {
  width: 15px;
  /* height: 10px !important; */
}

.pdpa::-webkit-scrollbar-track {
  width: 40px;
  box-shadow: inset 0 0 4px rgb(222, 72, 0);
  padding: 10px 10px;
  border-radius: 50px !important;
}

.pdpa::-webkit-scrollbar-thumb {
  background: rgb(222, 72, 0);
  width: 10px !important;
  min-height: 10px !important;
  border-radius: 50px !important;
  border: 1px;
}

.carousel-indicators {
  align-items: center !important;
}

.swiper-pagination-bullet {
  width: 0.75rem !important;
  height: 0.75rem !important;
  border-radius: 50% !important;
  bottom: 15px !important;
  background-color: #cfc8b8 !important;
  position: relative !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 0.85rem !important;
  height: 0.85rem !important;
  border-radius: 50% !important;
  bottom: 15px !important;
  background-color: white !important;
  position: relative !important;
  border: 1px solid #cfc8b8 !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: 8% !important;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(0, 0, 0, 0.64);
  border-radius: 50%;
  width: 35px;
  background-size: 60% 60%;
  color: white !important;
  width: 35px !important;
  height: 35px !important;
}

.swiper-button-prev::after {
  font-size: 20px !important;
}

.swiper-button-next::after {
  font-size: 20px !important;
}

.reelDesc {
  color: white !important;
}

.reelDesc span {
  color: white !important;
  font-size: 15px !important;
}

div.live-chat-loader-placeholder {
  z-index: 2 !important;
}

.reel-item video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}

.fb-customerchat {
  z-index: 2 !important;
}

.styles-module_whatsappButton__tVits {
  bottom: 6rem !important;
  right: 1.5rem !important;
}