.product-media .slick-next:before, .slick-prev::before {
    content: none !important;
}

.product-media .slick-dots .slick-active div {
    background-color: white;
    border: 2px solid #CFC8B8;
    width: 0.875rem;
    height: 0.875rem;
}

.product-media .slick-dots li {
    display: flex;
    align-items: center;
}

.product-media .slick-dots li {
    margin: 0 0;
}